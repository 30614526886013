import { Button, Helper, Table } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { BCAttribute } from '../../../model/BCAttribute';
import { selectAttributeMapping } from '../../selector/selectAttributeMapping';
import { useSelector } from '../../store/StoreProvider';
import { MappingState } from './MappingState';
import { MediaMappingState } from '../../MediaMapping/component/MediaMappingState';
import { FormattedPimAttributeTypes } from '../../component/FormattedPimAttributeTypes';

const BCAttributeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 26px;
`;

const PimAttributeCell = styled(Table.Cell)`
    overflow: initial;
`;

const PimAttributeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 300px;
`;

const ButtonContainer = styled.div`
    text-align: right;
    width: 100%;
`;

type Props = {
    bcAttribute: BCAttribute;
    children: React.ReactNode;
};

export const MappingItem = ({ bcAttribute, children }: Props) => {
    const navigate = useNavigate();

    const attributeMapping = useSelector(
        selectAttributeMapping(bcAttribute.code),
    );

    const capitalizeAndKeepUpperCases = (value: string) => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    };

    return (
        <Table.Row data-testid={bcAttribute.code}>
            <Table.Cell>
                <BCAttributeContainer>
                    <div>
                        {bcAttribute.label}
                        {bcAttribute.isRequired && (
                            <i>
                                &nbsp;(
                                <FormattedMessage
                                    id='Seanpx'
                                    defaultMessage='Required'
                                />
                                )
                            </i>
                        )}
                    </div>
                    <Helper inline level='info'>
                        <FormattedMessage
                            id='e0i+2C'
                            defaultMessage='{typesCount, plural, one {Compatible PIM attribute type: {types}} other {Compatible PIM attribute types: {types}}}'
                            values={{
                                types: (
                                    <FormattedPimAttributeTypes
                                        types={[...bcAttribute.pimAllowedTypes]}
                                    />
                                ),
                                typesCount: bcAttribute.pimAllowedTypes.length,
                            }}
                        />

                        {bcAttribute.constraint && (
                            <>
                                <br />
                                <FormattedMessage
                                    id='FpF8hi'
                                    defaultMessage='Limitation: {constraint}'
                                    values={{
                                        constraint: capitalizeAndKeepUpperCases(
                                            bcAttribute.constraint,
                                        ),
                                    }}
                                />
                            </>
                        )}
                    </Helper>
                </BCAttributeContainer>
            </Table.Cell>

            <PimAttributeCell>
                <PimAttributeContainer>{children}</PimAttributeContainer>
            </PimAttributeCell>

            <Table.Cell>
                {attributeMapping.bcAttributeCode === 'IMAGES' ? (
                    <MediaMappingState />
                ) : (
                    <MappingState bcAttribute={bcAttribute} />
                )}
            </Table.Cell>

            <Table.Cell>
                <ButtonContainer>
                    {attributeMapping.hasAttributePerFamily && (
                        <Button
                            ghost
                            level='secondary'
                            onClick={() =>
                                navigate(`${bcAttribute.code}/family-mapping`)
                            }
                        >
                            <FormattedMessage
                                id='SUOxRz'
                                defaultMessage='Manage family mapping'
                            />
                        </Button>
                    )}
                </ButtonContainer>
            </Table.Cell>
        </Table.Row>
    );
};
