import { Helper, Table } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { Loading } from '../../../component';
import { useCurrentStore } from '../../MultiStore';
import { getSelectedCustomFields } from '../api/getSelectedCustomFields';
import { NoCustomFields } from './NoCustomFields';
import { ManageCustomFieldsButton } from '../ManageCustomFieldsButton';
import { FormattedPimAttributeTypes } from '../../NativeFields/Product/component/FormattedPimAttributeTypes';
import React from 'react';

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    align-items: flex-end;
`;

const ColoredLabel = styled.span`
    color: ${({ theme }) => theme.color.brand100};
`;

export const CustomFieldsSelected = () => {
    const { currentStoreId } = useCurrentStore();
    const { data } = useQuery([currentStoreId, 'getSelectedCustomFields'], () =>
        getSelectedCustomFields(currentStoreId),
    );

    return (
        <>
            <div>
                <Helper level='info'>
                    <FormattedMessage
                        id='f+un2R'
                        defaultMessage="Custom fields allow you to list additional product information that will appear on the product page, such as a book's ISBN, a film's release date, or a bottle of wine's vintage.
                        Please use custom fields to import any specific field not proposed as a native field in your BigCommerce Backoffice."
                    />
                </Helper>
            </div>
            {data !== undefined ? (
                data.length > 0 ? (
                    <>
                        <ButtonContainer>
                            <ManageCustomFieldsButton />
                        </ButtonContainer>
                        <Table>
                            <Table.Header>
                                <Table.HeaderCell>
                                    <FormattedMessage
                                        id='h4QvQI'
                                        defaultMessage='PIM attribute'
                                    />
                                </Table.HeaderCell>

                                <Table.HeaderCell>
                                    <FormattedMessage
                                        id='8Gmvj8'
                                        defaultMessage='Attribute code'
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <FormattedMessage
                                        defaultMessage='Attribute type'
                                        id='O8FPkm'
                                    />
                                </Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                                {data?.map((customField) => (
                                    <Table.Row key={customField.code}>
                                        <Table.Cell>
                                            <ColoredLabel>
                                                {customField.label}
                                            </ColoredLabel>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {customField.code}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <FormattedPimAttributeTypes
                                                types={[
                                                    customField.attributeTypeLabel,
                                                ]}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </>
                ) : (
                    <NoCustomFields />
                )
            ) : (
                <Loading />
            )}
        </>
    );
};
