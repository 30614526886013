import { apiFetch } from '../../../util/apiFetch';

type Result = {
    data: {
        storefronts: [
            {
                storefront_id: string;
                storefront_name: string;
            },
        ];
        display_option_types: [
            {
                display_option_type_code: string;
                display_option_type_label: string;
            },
        ];
    };
};

export const getBCConfigOptions = (storeId: string) =>
    apiFetch<Result>(`${storeId}/configuration/retrieveBCConfigOptions`).then(
        function (result) {
            return {
                storefronts: result.data.storefronts.map((storefront) => ({
                    storefrontId: storefront.storefront_id,
                    storefrontName: storefront.storefront_name,
                })),
                displayOptionTypes: result.data.display_option_types.map(
                    (displayOptionType) => ({
                        displayOptionTypeCode:
                            displayOptionType.display_option_type_code,
                        displayOptionTypeLabel:
                            displayOptionType.display_option_type_label,
                    }),
                ),
            };
        },
    );
