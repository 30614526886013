import { Button, Table } from 'akeneo-design-system';
import { DateTime } from 'luxon';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { DateTimeLabel } from '../../../../component';
import { SynchronizationScopeLabel } from '../../component/SynchronizationScopeLabel';
import { SynchronizationTypeLabel } from '../../component/SynchronizationTypeLabel';
import { SynchronizationHistory } from '../../model/SynchronizationHistory';
import { SynchronizationHistoryStatus } from '../../model/SynchronizationHistoryStatus';
import { SynchronizationType } from '../../model/SynchronizationType';
import { SynchronizationHistoryStatusBadge } from './SynchronizationHistoryStatusBadge';
import { SynchronizationHistoryStatusMessage } from './SynchronizationHistoryStatusMessage';
import { SynchronizationScheduledManualLabel } from '../../component/SynchronizationScheduledManualLabel';
import { useNavigate } from 'react-router-dom';

const StatusMessageContainer = styled.div`
    white-space: normal;
`;

const LabelContainer = styled.div`
    line-height: 25px;
`;

const TableActionCell = styled(Table.ActionCell)`
    & > div {
        opacity: 1 !important;
        justify-content: flex-end;
    }
`;

type Props = {
    synchronizationHistory: SynchronizationHistory;
};

export const SynchronizationHistoryItem = ({
    synchronizationHistory,
}: Props) => {
    const navigate = useNavigate();

    return (
        <Table.Row>
            <Table.Cell>
                <DateTimeLabel dateTime={synchronizationHistory.createdAt} />
            </Table.Cell>
            <Table.Cell>
                {DateTime.fromISO(synchronizationHistory.endedAt)
                    .diff(DateTime.fromISO(synchronizationHistory.createdAt), [
                        'hour',
                        'minute',
                        'second',
                    ])
                    .toFormat('hh:mm:ss')}
            </Table.Cell>
            <Table.Cell>
                <LabelContainer>
                    <SynchronizationTypeLabel
                        type={synchronizationHistory.type}
                    />
                    <br />
                    <SynchronizationScheduledManualLabel
                        isScheduledSync={synchronizationHistory.isScheduledSync}
                    />
                </LabelContainer>
            </Table.Cell>
            <Table.Cell>
                {synchronizationHistory.type ===
                    SynchronizationType.Differential &&
                    DateTime.fromISO(
                        synchronizationHistory.referenceDate,
                    ).toLocaleString(DateTime.DATETIME_SHORT)}
            </Table.Cell>
            <Table.Cell>
                <SynchronizationScopeLabel
                    scope={synchronizationHistory.scope}
                    colored
                />
            </Table.Cell>
            <Table.Cell>
                <SynchronizationHistoryStatusBadge
                    status={synchronizationHistory.status}
                />
            </Table.Cell>
            <Table.Cell>
                <StatusMessageContainer>
                    {synchronizationHistory.status ===
                    SynchronizationHistoryStatus.Warning ? (
                        <SynchronizationHistoryStatusMessage
                            status={synchronizationHistory.status}
                            warningCount={synchronizationHistory.warningCount}
                            errorCount={synchronizationHistory.errorCount}
                        />
                    ) : (
                        <SynchronizationHistoryStatusMessage
                            status={synchronizationHistory.status}
                        />
                    )}
                </StatusMessageContainer>
            </Table.Cell>
            <TableActionCell>
                {synchronizationHistory.reportVersion > 0 ? (
                    <Button
                        ghost
                        level='tertiary'
                        onClick={() =>
                            navigate(
                                `synchronization-report/${synchronizationHistory.id}`,
                                {
                                    state: {
                                        synchronizationId:
                                            synchronizationHistory.id,
                                    },
                                },
                            )
                        }
                    >
                        <FormattedMessage
                            id='/O7rVJ'
                            defaultMessage='See Report'
                        />
                    </Button>
                ) : (
                    <Button
                        level='tertiary'
                        ghost
                        href={`/api-web/synchronization/${synchronizationHistory.id}/downloadSynchronizationLogs`}
                        target='_blank'
                    >
                        <FormattedMessage
                            id='4P3axO'
                            defaultMessage='Download all logs'
                        />
                    </Button>
                )}
            </TableActionCell>
        </Table.Row>
    );
};
