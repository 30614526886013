import { apiFetch, BadRequestError } from '../../../util/apiFetch';

type Config = {
    categoryCode: string;
    locale: string | null;
    channel: string | null;
    currency: string | null;
    pre_existing_catalog_mapping?: {
        bigcommerce_product_identifier: string | null;
        pim_product_identifier: string | null;
        bigcommerce_variant_identifier: string | null;
        pim_variant_identifier: string | null;
    };
};

export const saveConnectorConfig = (config: Config, storeId: string) =>
    apiFetch(`${storeId}/configuration/saveConfig`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            categoryCode: config.categoryCode || '',
            locale: config.locale || '',
            channel: config.channel || '',
            currency: config.currency || '',
            bigcommerce_product_identifier:
                config.pre_existing_catalog_mapping
                    ?.bigcommerce_product_identifier || '',
            pim_product_identifier:
                config.pre_existing_catalog_mapping?.pim_product_identifier ||
                '',
            bigcommerce_variant_identifier:
                config.pre_existing_catalog_mapping
                    ?.bigcommerce_variant_identifier || '',
            pim_variant_identifier:
                config.pre_existing_catalog_mapping?.pim_variant_identifier ||
                '',
        }),
    });

export const isSaveConnectorConfigValidationError = (
    error: unknown,
): error is BadRequestError<{
    errors: {
        categoryCode: string[];
        locale: string[];
        channel: string[];
        currency: string[];
        bigcommerce_product_identifier?: string[];
        pim_product_identifier?: string[];
        bigcommerce_variant_identifier?: string[];
        pim_variant_identifier?: string[];
    };
}> => error instanceof BadRequestError;
