import { Helper, SectionTitle } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { useCurrentStore } from '../../MultiStore';
import { fetchSynchronizationHistories } from '../api/fetchSynchronizationHistories';
import { SynchronizationHistoryItem } from './component/SynchronizationHistoryItem';
import {
    SynchronizationHistoryList,
    SynchronizationHistoryListSkeleton,
} from './component/SynchronizationHistoryList';
import { NoHistoryPlaceholder } from './component/NoHistoryPlaceholder';

export const HistoryTab = () => {
    const { currentStoreId } = useCurrentStore();
    const query = useQuery(
        [currentStoreId, 'fetchSynchronizationHistories'],
        () => fetchSynchronizationHistories(currentStoreId),
        { refetchInterval: 5000 },
    );
    return (
        <>
            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage id='djJp6c' defaultMessage='History' />
                </SectionTitle.Title>
            </SectionTitle>

            <Helper level='info'>
                <FormattedMessage
                    defaultMessage="Here, you'll access your completed or interrupted synchronizations and their logs for more details."
                    id='boo249'
                />
            </Helper>

            {query.isLoading && <SynchronizationHistoryListSkeleton />}
            {query.isSuccess && query.data.length > 0 ? (
                <SynchronizationHistoryList>
                    {query.data.map((synchronizationHistory) => (
                        <SynchronizationHistoryItem
                            key={synchronizationHistory.id}
                            synchronizationHistory={synchronizationHistory}
                        />
                    ))}
                </SynchronizationHistoryList>
            ) : (
                <NoHistoryPlaceholder />
            )}
        </>
    );
};
