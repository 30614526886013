import { TextInput } from 'akeneo-design-system';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import styled from 'styled-components';
import { dateTimeToIso8601 } from '../util/dateTimeToIso8601';

const Container = styled.div`
    display: flex;
    gap: 10px;
`;

const DateContainer = styled.div`
    width: 200px;
`;
const TimeContainer = styled.div`
    width: 150px;
`;

type Props = {
    dateTime: string;
    onChange: (dateTime: string) => void;
};

export const DateTimeInput = ({ dateTime, onChange }: Props) => {
    const [date, setDate] = useState<string>(
        DateTime.fromISO(dateTime).toFormat('yyyy-LL-dd'),
    );
    const [time, setTime] = useState<string>(
        DateTime.fromISO(dateTime).toFormat('HH:mm'),
    );

    const handleDateChange = (date: string) => {
        setDate(date);
        handleChange(date, time);
    };

    const handleTimeChange = (time: string) => {
        setTime(time);
        handleChange(date, time);
    };

    const handleChange = (date: string, time: string) =>
        onChange(dateTimeToIso8601(DateTime.fromISO(`${date}T${time}`)));

    return (
        <Container>
            <DateContainer>
                <TextInput
                    type='date'
                    value={date}
                    onChange={handleDateChange}
                />
            </DateContainer>
            <TimeContainer>
                <TextInput
                    type='time'
                    value={time}
                    onChange={handleTimeChange}
                />
            </TimeContainer>
        </Container>
    );
};
