import { useQuery } from 'react-query';
import { useCurrentStore } from '../../../MultiStore';
import { getPimAttributes } from '../../api/getPimAttributes';
import { BCVariantAttribute } from '../model/BCVariantAttribute';
import { PimAttribute } from '../model/PimAttribute';

export const useAllowedPimAttributes = (
    bcVariantAttribute: BCVariantAttribute,
) => {
    const { currentStoreId } = useCurrentStore();
    const pimAttributesQuery = useQuery(
        [currentStoreId, 'getPimAttributes'],
        () => getPimAttributes(currentStoreId),
    );
    let allowedPimAttribute: PimAttribute[] = [];
    if (pimAttributesQuery.isSuccess) {
        // @ts-ignore
        allowedPimAttribute = Object.values(pimAttributesQuery.data).filter(
            // @ts-ignore
            ({ type }) => bcVariantAttribute.pimAllowedTypes.includes(type),
        );
    }
    return allowedPimAttribute;
};
