import { apiFetch } from '../../../util/apiFetch';
import { SynchronizationReportModel } from '../model/SynchronizationReportModel';

export const getSynchronizationReport = (
    storeId: string,
    synchronizationId: string,
) =>
    apiFetch<SynchronizationReportModel>(
        `${storeId}/synchronization/${synchronizationId}/retrieve-synchronization-report`,
    ).then((result) => result);
