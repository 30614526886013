import { apiFetch } from '../../../util/apiFetch';

export const saveScheduledSyncConfig = ({
    currentStoreId,
    frequency,
    scope,
    filterAttribute,
    priceImport,
    withCustomFields,
    withImages,
    withImportPreExistingProductsOnly,
    withCategories,
}: {
    currentStoreId: string;
    frequency: string;
    scope: string;
    filterAttribute: string | null;
    priceImport: boolean;
    withCustomFields: boolean;
    withImages: boolean;
    withImportPreExistingProductsOnly: boolean;
    withCategories: boolean;
}) => {
    return apiFetch(
        `${currentStoreId}/scheduled-sync/save-scheduled-sync-config`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                frequency,
                scope,
                filterAttribute,
                priceImport,
                withCustomFields,
                withImages,
                withImportPreExistingProductsOnly,
                withCategories,
            }),
        },
    );
};
