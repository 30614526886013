import React from 'react';
import { PageContent } from '../../component';

import {
    Link,
    Placeholder,
    UserGroupsIllustration,
} from 'akeneo-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { apiFetch } from '../../util/apiFetch';

export const PimOauthError = () => {
    const intl = useIntl();
    const { isSuccess, data } = useQuery('retrievePimUrl', () =>
        apiFetch<{ pim_url: string }>('retrievePimUrl'),
    );
    if (!isSuccess) {
        return null;
    }

    return (
        <>
            <PageContent>
                <Placeholder
                    illustration={<UserGroupsIllustration />}
                    title={intl.formatMessage({
                        id: 'q7Gi1P',
                        defaultMessage:
                            'The connection between the Akeneo PIM and Big Commerce was not successful.',
                    })}
                    size='large'
                >
                    <FormattedMessage
                        id='U1tYC+'
                        defaultMessage='Please go back to the Akeneo PIM and try again. {br}If the problem persists, please contact Akeneo support.'
                        values={{ br: <br /> }}
                    />

                    <Link href={data.pim_url} target={'_blank'}>
                        {intl.formatMessage({
                            id: 'QFOInc',
                            defaultMessage: 'Reconnect to the PIM.',
                        })}
                    </Link>
                </Placeholder>
            </PageContent>
        </>
    );
};
