import { SectionTitle } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import { useQuery, useQueryClient } from 'react-query';
import { useCurrentStore } from '../../MultiStore';
import { DeleteStoreButton } from '../../MultiStore/component/DeleteStoreButton';
import { useDeleteStore } from '../../MultiStore/hook/useDeleteStore';
import styled from 'styled-components';
import { isSynchronizationRunning } from '../../Synchronization/api/isSynchronizationRunning';
import { useNavigate } from 'react-router';

const DeleteStoreButtonContainer = styled.div`
    display: flex;
`;

export const StoreConfigForm = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { currentStoreId } = useCurrentStore();
    const { deleteStore } = useDeleteStore();

    const { data: isSynchronizationRunningData } = useQuery(
        [currentStoreId, 'isSynchronizationRunning'],
        () => isSynchronizationRunning(currentStoreId!),
        {
            refetchInterval: 5000,
        },
    );

    const handleDelete = async (storeId: string) => {
        await deleteStore(storeId);
        await queryClient.invalidateQueries('getStores');
        navigate(`/`);
    };

    return (
        <>
            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage
                        id='D2KT2J'
                        defaultMessage='Store settings'
                    />
                </SectionTitle.Title>
                <SectionTitle.Spacer />
            </SectionTitle>

            <br />

            <DeleteStoreButtonContainer>
                <DeleteStoreButton
                    disabled={isSynchronizationRunningData ?? true}
                    level={'danger'}
                    ghost={false}
                    onConfirm={() => handleDelete(currentStoreId)}
                ></DeleteStoreButton>
            </DeleteStoreButtonContainer>
        </>
    );
};
