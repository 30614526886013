import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { CustomModal, Spacer } from '../../../component';
import { SaveActionButtons } from '../component/MediaMapping/SaveActionButtons';
import { SelectMediaSourceSection } from '../component/MediaMapping/SelectMediaSourceSection';
import { MediaSource } from '../model/MediaSource';
import { SelectAssetAttributeSection } from './component/SelectAssetAttributeSection';
import { SelectImageAttributeSection } from './component/SelectImageAttributeSection';
import { useMediaMapping } from './hook/useMediaMapping';
import { useSaveMediaMapping } from './hook/useSaveMediaMapping';

export const ProductVariantMediaMapping = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const { mapping, setMapping } = useMediaMapping();
    const saveMediaMapping = useSaveMediaMapping();

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const handleSourceChange = (source: MediaSource) => {
        setHasUnsavedChanges(true);
        setMapping({ source, attributeCode: null });
    };

    const handleImageMappingChange = (attributeCode: string | null) => {
        setHasUnsavedChanges(true);
        setMapping({
            source: MediaSource.Images,
            attributeCode,
        });
    };

    const handleAssetMappingChange = (
        value:
            | { attributeCode: null }
            | { attributeCode: string; assetAttributeCode: null | string },
    ) => {
        setHasUnsavedChanges(true);
        if (value.attributeCode === null) {
            setMapping({
                source: MediaSource.Assets,
                attributeCode: null,
            });
        } else {
            setMapping({
                source: MediaSource.Assets,
                attributeCode: value.attributeCode,
                assetAttributeCode: value.assetAttributeCode,
            });
            if (value.assetAttributeCode === null) {
                setHasUnsavedChanges(false);
            }
        }
    };

    const handleSave = async () => {
        setHasUnsavedChanges(false);
        await saveMediaMapping(mapping);
        navigate('..');
    };

    const handleCancel = () => navigate('..');

    return (
        <CustomModal
            title={intl.formatMessage({
                id: 'OBUN49',
                defaultMessage: 'Media Mapping',
            })}
            subTitle={intl.formatMessage({
                id: 'EGMWtj',
                defaultMessage: 'Big commerce target: Image',
            })}
            actions={
                <SaveActionButtons
                    onSave={handleSave}
                    onCancel={handleCancel}
                    disabled={!hasUnsavedChanges}
                />
            }
        >
            <SelectMediaSourceSection
                contextProduct='variant'
                value={mapping.source}
                onChange={handleSourceChange}
                handleImageMappingChange={handleImageMappingChange}
                handleAssetMappingChange={handleAssetMappingChange}
                disabled={mapping.attributeCode !== null}
            />
            <Spacer />
            {mapping.source === MediaSource.Images && (
                <SelectImageAttributeSection
                    value={mapping.attributeCode}
                    onChange={handleImageMappingChange}
                />
            )}
            {mapping.source === MediaSource.Assets && (
                <SelectAssetAttributeSection
                    value={mapping}
                    onChange={handleAssetMappingChange}
                />
            )}
        </CustomModal>
    );
};
