import React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ValidConfigurationGuard } from '../Configuration';
import { RunningSynchronizationGuard } from '../Synchronization';
import { DisabledMapping } from './component/DisabledMapping';
import { InvalidConfiguration } from './component/InvalidConfiguration';
import { ManageMapping } from './ManageMapping';
import { MediaMapping } from './Product/MediaMapping/MediaMapping';
import { MediaMappingProvider } from './Product/MediaMapping/MediaMappingProvider';
import { ManageFamilyMapping } from './Product/ProductMappingPerFamily/ManageFamilyMapping';
import { ProductMappingProvider } from './Product/ProductMappingProvider';
import { ProductVariantMappingProvider } from './ProductVariant/ProductVariantMappingProvider';
import { ProductVariantMediaMapping } from './ProductVariantMediaMapping';

export const NativeFields = () => {
    const navigate = useNavigate();
    return (
        <Guard>
            <ProductMappingProvider>
                <ProductVariantMappingProvider>
                    <MediaMappingProvider>
                        <Routes>
                            <Route path='/' element={<ManageMapping />} />
                            <Route
                                path='media-mapping'
                                element={<MediaMapping />}
                            />
                            <Route
                                path=':bcAttributeCode/family-mapping'
                                element={<ManageFamilyMapping />}
                            />
                            <Route
                                path='variant/media-mapping'
                                element={<ProductVariantMediaMapping />}
                            />
                            <Route
                                path='*'
                                element={<Navigate to='/' replace />}
                            />
                        </Routes>
                    </MediaMappingProvider>
                </ProductVariantMappingProvider>
            </ProductMappingProvider>
        </Guard>
    );
};

const Guard = ({ children }: { children: React.ReactNode }) => {
    return (
        <RunningSynchronizationGuard fallback={() => <DisabledMapping />}>
            <ValidConfigurationGuard
                fallback={(
                    isBCCredentialsInvalid,
                    isConfigInvalid,
                    isBcConfigInvalid,
                ) => (
                    <InvalidConfiguration
                        isBCCredentialsInvalid={isBCCredentialsInvalid}
                        isConfigInvalid={isConfigInvalid}
                        isBcConfigInvalid={isBcConfigInvalid}
                    />
                )}
            >
                {children}
            </ValidConfigurationGuard>
        </RunningSynchronizationGuard>
    );
};
