import {
    Field,
    Helper,
    Link,
    SectionTitle,
    Tag,
    Tags,
    TextInput,
} from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BCCredentials } from '../model/BCCredentials';
import { useDispatch, useSelector } from '../store/StoreProvider';
import { ConnectionStatusLabel } from './ConnectionStatusLabel';
import { helpCenterSetUpBigcommerceCredentialsUrl } from '../../../util/config';
import { ConnectionStatus } from '../model/ConnectionStatus';

const defaultBCCredentials: BCCredentials = {
    accessToken: '',
    storeHash: '',
};

export const BCCredentialsForm = () => {
    const intl = useIntl();

    const dispatch = useDispatch();
    const { bcCredentials, errors, connectionStatus, disabled } = useSelector(
        (state) => ({
            bcCredentials: state.bcCredentialsForm.data || defaultBCCredentials,
            errors: state.bcCredentialsForm.errors,
            connectionStatus: state.bcConnectionStatus,
            disabled: false,
        }),
    );

    const handleChange = (bcCredentials: BCCredentials) =>
        dispatch({
            type: 'bcCredentialsForm/changed',
            bcCredentials,
        });

    const requiredLabel = intl.formatMessage({
        id: 'VG94fP',
        defaultMessage: '(required)',
    });

    const mapError = (errors: readonly string[]) =>
        errors.map((error, idx) => (
            <Helper key={idx} inline level='error'>
                {error}
            </Helper>
        ));

    return (
        <>
            <SectionTitle>
                <Tags>
                    <Tag tint='dark_blue'>BigCommerce</Tag>
                </Tags>
                <SectionTitle.Title>
                    <FormattedMessage
                        id='isBo45'
                        defaultMessage='Rest API - Credentials'
                    />
                </SectionTitle.Title>
                <SectionTitle.Spacer />
                <ConnectionStatusLabel status={connectionStatus} />
            </SectionTitle>

            <Helper level='info'>
                <FormattedMessage
                    id='XbaN11'
                    defaultMessage='To fill in the fields below, go to the BigCommerce connection page inside the advanced settings menu.'
                />{' '}
                <Link
                    href={helpCenterSetUpBigcommerceCredentialsUrl}
                    target='_blank'
                >
                    <FormattedMessage
                        id='iMmZy3'
                        defaultMessage='Check out our Help Center for more information.'
                    />
                </Link>
            </Helper>

            <br />

            <form autoComplete='off'>
                <Field
                    label={intl.formatMessage({
                        id: 'UHGqvP',
                        defaultMessage: 'Store hash',
                    })}
                    requiredLabel={requiredLabel}
                >
                    <TextInput
                        placeholder={intl.formatMessage({
                            id: 'AUORD6',
                            defaultMessage: 'Define the store hash',
                        })}
                        value={bcCredentials.storeHash}
                        data-testid='bc-storehash'
                        readOnly={true}
                        invalid={!!errors?.storeHash || connectionStatus === 4}
                        autoComplete='off'
                        onChange={(storeHash) =>
                            handleChange({
                                ...bcCredentials,
                                storeHash,
                            })
                        }
                    />
                    {connectionStatus ===
                        ConnectionStatus.InvalidCredentials && (
                        <Helper inline level='error'>
                            {intl.formatMessage({
                                defaultMessage:
                                    'The BigCommerce credentials are invalid.',
                                id: '13xp8y',
                            })}
                        </Helper>
                    )}
                    {connectionStatus === ConnectionStatus.MissingApiScopes && (
                        <Helper inline level='error'>
                            {intl.formatMessage({
                                defaultMessage:
                                    "The BigCommerce credentials don't have required API scopes.",
                                id: '0kyEQI',
                            })}
                        </Helper>
                    )}
                    {errors?.storeHash && mapError(errors.storeHash)}
                </Field>

                <br />

                <Field
                    label={intl.formatMessage({
                        id: 'hKIibs',
                        defaultMessage: 'Access Token',
                    })}
                    requiredLabel={requiredLabel}
                >
                    <TextInput
                        type='password'
                        placeholder={intl.formatMessage({
                            id: 'PcPA/V',
                            defaultMessage: 'Define the access Token',
                        })}
                        value={bcCredentials.accessToken}
                        data-testid='bc-access-token'
                        readOnly={disabled}
                        invalid={
                            !!errors?.accessToken || connectionStatus === 4
                        }
                        autoComplete='off'
                        onChange={(accessToken) =>
                            handleChange({
                                ...bcCredentials,
                                accessToken,
                            })
                        }
                    />
                    {errors?.accessToken && mapError(errors.accessToken)}
                    {connectionStatus ===
                        ConnectionStatus.InvalidCredentials && (
                        <Helper inline level='error'>
                            {intl.formatMessage({
                                defaultMessage:
                                    'The BigCommerce credentials are invalid.',
                                id: '13xp8y',
                            })}
                        </Helper>
                    )}
                    {connectionStatus === ConnectionStatus.MissingApiScopes && (
                        <Helper inline level='error'>
                            {intl.formatMessage({
                                defaultMessage:
                                    "The BigCommerce credentials don't have required API scopes.",
                                id: '0kyEQI',
                            })}
                        </Helper>
                    )}
                </Field>

                <br />
            </form>
        </>
    );
};
