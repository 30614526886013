import { apiFetch } from '../../../util/apiFetch';

type Result = {
    data: {
        store_hash: string;
        access_token: string;
    } | null;
};

export const getBCCredentials = (storeId: string) =>
    apiFetch<Result>(`${storeId}/configuration/getBcCredentials`).then(
        (result) => {
            if (result.data === null) {
                return null;
            }

            return {
                storeHash: result.data.store_hash,
                accessToken: result.data.access_token,
            };
        },
    );
