import { ClientErrorIllustration, Placeholder } from 'akeneo-design-system';
import React from 'react';
import { useIntl } from 'react-intl';

type Props = {
    isBCCredentialsInvalid: boolean;
    isConfigInvalid: boolean;
    isBcConfigInvalid: boolean;
};

export const InvalidConfiguration = ({
    isBCCredentialsInvalid,
    isConfigInvalid,
    isBcConfigInvalid,
}: Props) => {
    const intl = useIntl();

    const title = isBCCredentialsInvalid
        ? intl.formatMessage({
              id: 'Dwm42n',
              defaultMessage:
                  'To access the mapping page, please start by configuring the App.',
          })
        : isConfigInvalid
          ? intl.formatMessage({
                defaultMessage:
                    'Please finish the Store configuration in the Configuration page, then you will be able to start mapping.',
                id: 'Hfd0ez',
            })
          : isBcConfigInvalid
            ? intl.formatMessage({
                  id: 'YiK3qF',
                  defaultMessage:
                      'Please finish the BC configuration in the Configuration page, then you will be able to start mapping.',
              })
            : '';

    return (
        <Placeholder
            illustration={<ClientErrorIllustration />}
            size='large'
            title={title}
        />
    );
};
