import { Immutable, produce } from 'immer';
import { ProductVariantsMapping } from '../model/ProductVariantsMapping';

export type State = Immutable<{
    productVariantMapping: ProductVariantsMapping;
    productVariantMappingIsDirty: boolean;
}>;

export const initialState: State = {
    productVariantMapping: new Map(),
    productVariantMappingIsDirty: false,
};

export type Action =
    | {
          type: 'fetchProductVariantsMapping/fulfilled';
          productVariantMapping: {
              [bcAttributeCode: string]: {
                  bcAttributeCode: string;
                  data: string;
              };
          };
      }
    | {
          type: 'variantAttributeMapped';
          bcAttributeCode: string;
          pimAttributeCode: string;
      }
    | {
          type: 'variantAttributeUnmapped';
          bcAttributeCode: string;
      }
    | {
          type: 'saveProductVariantMapping/fulfilled';
      };

export const reducer = produce<(draft: State, action: Action) => State>(
    (draft, action) => {
        switch (action.type) {
            case 'fetchProductVariantsMapping/fulfilled':
                Object.values(action.productVariantMapping).forEach(
                    (variantAttributeMapping) => {
                        draft.productVariantMapping.set(
                            variantAttributeMapping.bcAttributeCode,
                            {
                                bcAttributeCode:
                                    variantAttributeMapping.bcAttributeCode,
                                data: variantAttributeMapping.data,
                            },
                        );
                    },
                );
                draft.productVariantMappingIsDirty = false;
                break;

            case 'variantAttributeMapped':
                draft.productVariantMapping.set(action.bcAttributeCode, {
                    bcAttributeCode: action.bcAttributeCode,
                    data: action.pimAttributeCode,
                });
                draft.productVariantMappingIsDirty = true;
                break;

            case 'variantAttributeUnmapped':
                draft.productVariantMapping.set(action.bcAttributeCode, {
                    bcAttributeCode: action.bcAttributeCode,
                    data: null,
                });
                draft.productVariantMappingIsDirty = true;
                break;

            case 'saveProductVariantMapping/fulfilled':
                draft.productVariantMappingIsDirty = false;
                break;
        }

        return draft;
    },
);
