import { apiFetch } from '../../../util/apiFetch';

type Result = {
    is_empty: boolean;
};

export const isProductWithVariantsMediaMappingEmpty = (
    currentStoreId: string,
) =>
    apiFetch<Result>(
        `${currentStoreId}/synchronization/is-product-with-variants-media-mapping-empty`,
    ).then((result) => result);
