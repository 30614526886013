import { apiFetch } from '../../../util/apiFetch';

type Result = {
    data: Array<{
        code: string;
        type: string;
        label: string;
        attributeTypeLabel: string;
    }> | null;
    error?: string;
};

export const retrievePimAttributesForCustomFieldsSelection = (
    storeId: string,
) =>
    apiFetch<Result>(
        `${storeId}/mapping/retrievePimAttributesForCustomFieldsSelection`,
    ).then((result) => result);
