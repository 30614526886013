import { Helper, Table } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { BCVariantAttribute } from '../model/BCVariantAttribute';
import { FormattedPimAttributeTypes } from '../../Product/component/FormattedPimAttributeTypes';

const BCAttributeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 26px;
`;

const PimAttributeCell = styled(Table.Cell)`
    overflow: initial;
`;

const PimAttributeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 300px;
`;

type Props = {
    bcVariantAttribute: BCVariantAttribute;
    mappingState: React.ReactNode;
    children: React.ReactNode;
};

export const MappingItem = ({
    bcVariantAttribute,
    mappingState,
    children,
}: Props) => {
    const capitalizeAndKeepUpperCases = (value: string) => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    };

    return (
        <Table.Row data-testid={bcVariantAttribute.code}>
            <Table.Cell>
                <BCAttributeContainer>
                    <div>
                        {bcVariantAttribute.label}
                        {bcVariantAttribute.isRequired && (
                            <i>
                                &nbsp;(
                                <FormattedMessage
                                    id='Seanpx'
                                    defaultMessage='Required'
                                />
                                )
                            </i>
                        )}
                    </div>
                    <Helper inline level='info'>
                        <FormattedMessage
                            id='GQlDiy'
                            defaultMessage='Compatible PIM attribute types: {types}'
                            values={{
                                types: (
                                    <FormattedPimAttributeTypes
                                        types={[
                                            ...bcVariantAttribute.pimAllowedTypes,
                                        ]}
                                    />
                                ),
                            }}
                        />

                        {bcVariantAttribute.constraint && (
                            <>
                                <br />
                                <FormattedMessage
                                    id='FpF8hi'
                                    defaultMessage='Limitation: {constraint}'
                                    values={{
                                        constraint: capitalizeAndKeepUpperCases(
                                            bcVariantAttribute.constraint,
                                        ),
                                    }}
                                />
                            </>
                        )}
                    </Helper>
                </BCAttributeContainer>
            </Table.Cell>

            <PimAttributeCell>
                <PimAttributeContainer>{children}</PimAttributeContainer>
            </PimAttributeCell>

            <Table.Cell>{mappingState}</Table.Cell>
        </Table.Row>
    );
};
