import {
    Field,
    Helper,
    SectionTitle,
    SelectInput,
    Tag,
    Tags,
} from 'akeneo-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { Skeleton } from '../../../component';
import { useCurrentStore } from '../../MultiStore';
import { getBCConfigOptions } from '../api/getBCConfigOptions';
import { BcConfig } from '../model/BcConfig';
import { ConnectionStatus } from '../model/ConnectionStatus';
import { useDispatch, useSelector } from '../store/StoreProvider';

const LabelSkeleton = styled(Skeleton)`
    height: 20px;
    margin-bottom: 10px;
    width: 25%;
`;

const FieldSkeleton = styled(Skeleton)`
    height: 40px;
`;

const defaultBcConnectorConfig: BcConfig = {
    storefrontId: '',
    displayOptionTypeCode: '',
};

export const BCStorefrontForm = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { connectorBcConfig, isBCCredentialsInvalid } = useSelector(
        (state) => ({
            connectorBcConfig:
                state.connectorBcConfigForm.data || defaultBcConnectorConfig,
            isBCCredentialsInvalid:
                state.bcConnectionStatus !== ConnectionStatus.Succeeded,
        }),
    );

    const { currentStoreId } = useCurrentStore();
    const { isFetching, data } = useQuery(
        [currentStoreId, 'getBCConfigOptions'],
        () => getBCConfigOptions(currentStoreId),
        {
            enabled: !isBCCredentialsInvalid,
        },
    );
    const handleChange = (bcConfig: BcConfig) =>
        dispatch({
            type: 'bcConfigForm/changed',
            bcConfig,
        });

    const storefrontExist = (storefrontId: string) =>
        data?.storefronts?.some(
            (storefront) => storefront.storefrontId === storefrontId,
        );

    return (
        <>
            <SectionTitle>
                <Tags>
                    <Tag tint='dark_blue'>BigCommerce</Tag>
                </Tags>
                <SectionTitle.Title>
                    <FormattedMessage id='LmKz3g' defaultMessage='Storefront' />
                </SectionTitle.Title>
                <SectionTitle.Spacer />
            </SectionTitle>

            <Helper level='info'>
                <FormattedMessage
                    defaultMessage='The selection of a storefront is required to finish the configuration of your store.'
                    id='w1oXnm'
                />
            </Helper>

            <br />

            <form>
                {!isFetching ? (
                    <Field
                        label={intl.formatMessage({
                            id: 'LmKz3g',
                            defaultMessage: 'Storefront',
                        })}
                    >
                        <SelectInput
                            placeholder={intl.formatMessage({
                                id: 'EAgWPJ',
                                defaultMessage: 'Select the Storefront',
                            })}
                            openLabel=''
                            value={
                                !isBCCredentialsInvalid &&
                                storefrontExist(connectorBcConfig.storefrontId)
                                    ? connectorBcConfig.storefrontId
                                    : ''
                            }
                            data-testid='bc-storefront'
                            onChange={(storefrontId: string) =>
                                handleChange({
                                    ...connectorBcConfig,
                                    storefrontId,
                                })
                            }
                            readOnly={isBCCredentialsInvalid}
                            clearable={false}
                            emptyResultLabel={intl.formatMessage({
                                id: 'bhR21O',
                                defaultMessage: 'No Storefront found',
                            })}
                        >
                            {data?.storefronts?.map((value) => (
                                <SelectInput.Option
                                    key={value.storefrontId}
                                    value={value.storefrontId}
                                >
                                    {value.storefrontName}
                                </SelectInput.Option>
                            ))}
                        </SelectInput>
                        {connectorBcConfig.storefrontId !== null &&
                        !storefrontExist(connectorBcConfig.storefrontId) &&
                        !isBCCredentialsInvalid ? (
                            <Helper inline level='error'>
                                {intl.formatMessage({
                                    id: 'tx6/rC',
                                    defaultMessage:
                                        'The previously selected storefront does not exist anymore. Please choose a new storefront.',
                                })}
                            </Helper>
                        ) : (
                            <></>
                        )}
                    </Field>
                ) : (
                    <>
                        <LabelSkeleton />
                        <FieldSkeleton />
                    </>
                )}

                <br />
            </form>
        </>
    );
};
