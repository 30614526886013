import { useIntl } from 'react-intl';
import { useToaster } from '../../../util/toast';
import { deleteStore as deleteStoreRequest } from '../api/deleteStore';
import { useMutation } from 'react-query';

export const useDeleteStore = () => {
    const intl = useIntl();
    const addToast = useToaster();
    const deleteStoreMutation = useMutation(deleteStoreRequest);

    const deleteStore = async (storeId: string) => {
        await deleteStoreMutation.mutateAsync(storeId);

        addToast(
            intl.formatMessage({
                id: 'FJsgzU',
                defaultMessage: 'Store successfully deleted.',
            }),
            'success',
        );
    };

    return { deleteStore };
};
