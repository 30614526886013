import { Immutable } from 'immer';
import { AttributeMapping } from '../../model/AttributeMapping';
import { CollectionEmptyAttributeMapping } from '../../model/CollectionEmptyAttributeMapping';
import { MappingStatus } from '../../model/MappingStatus';
import { SingleEmptyAttributeMapping } from '../../model/SingleEmptyAttributeMapping';
import { State } from '../store/reducer';

export const selectAttributeMapping = (bcAttributeCode: string) => {
    return (
        state: State,
    ): Immutable<
        | AttributeMapping
        | SingleEmptyAttributeMapping
        | CollectionEmptyAttributeMapping
    > => {
        const bcAttribute = state.bcAttributes[bcAttributeCode];
        if (!bcAttribute) {
            throw new Error();
        }

        const attributeMapping = state.mapping.get(bcAttributeCode);

        if (!attributeMapping) {
            if (bcAttribute.collection) {
                return {
                    bcAttributeCode,
                    hasCollectionOfAttribute: true,
                    hasAttributePerFamily: false,
                    pimAttributeCodes: [],
                    status: MappingStatus.None,
                };
            }

            return {
                bcAttributeCode,
                hasCollectionOfAttribute: false,
                hasAttributePerFamily: false,
                pimAttributeCode: null,
                status: MappingStatus.None,
            };
        }

        return attributeMapping;
    };
};
