import { Block, Button, RefreshIcon, SectionTitle } from 'akeneo-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { GlobalReportModel } from '../model/SynchronizationReportModel';
import { useToaster } from '../../../util/toast';
import { GlobalReportProcessedProduct } from './GlobalReportProcessedProduct';

const GlobalReportDiv = styled.div`
    width: 100%;
`;

const GlobalBlock = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 6px 0 6px 0;
    border-style: solid;
    border-width: 1px;
    border-radius: 2px;
    font-size: 13px;
    font-weight: 400;
    border-color: #c7cbd4;
`;
const GlobalBlockContent = styled.div`
    border-left: 1px solid #e8ebee;
    padding: 6px 20px 6px 20px;
`;
const Icon = styled.div`
    padding: 19px 25px;
`;

const ContentBlock = styled.div`
    margin-top: 10px;
    display: flex;
    gap: 10px;
    flex-flow: row wrap;
    color: ${({ theme }) => theme.color.grey100};
`;

const Grey = styled.b`
    color: ${({ theme }) => theme.color.grey140};
`;

type Props = {
    globalReport: GlobalReportModel | null;
    synchronizationId: string;
};

export const GlobalReport = ({ globalReport, synchronizationId }: Props) => {
    const addToast = useToaster();
    const intl = useIntl();

    return (
        <>
            <GlobalReportDiv>
                <SectionTitle>
                    <SectionTitle.Title>
                        <FormattedMessage
                            id='zvzs9o'
                            defaultMessage='Global Report'
                        />
                    </SectionTitle.Title>
                </SectionTitle>

                <br />

                <GlobalBlock>
                    <Icon>
                        <RefreshIcon
                            style={{ transform: 'rotate(90deg)' }}
                            color='#a1a9b7'
                            size={30}
                        />
                    </Icon>
                    <GlobalBlockContent>
                        <Grey>
                            <FormattedMessage
                                id='eOsloh'
                                defaultMessage='Synchronization'
                            />
                        </Grey>

                        <ContentBlock>
                            <GlobalReportProcessedProduct
                                globalReport={globalReport}
                            />
                        </ContentBlock>
                    </GlobalBlockContent>
                </GlobalBlock>

                <br />

                <Block
                    actions={
                        <>
                            <Button
                                ghost
                                level='secondary'
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        synchronizationId,
                                    );
                                    addToast(
                                        intl.formatMessage({
                                            id: 'vEiDnU',
                                            defaultMessage:
                                                'Synchronization ID copied to clipboard.',
                                        }),
                                        'info',
                                    );
                                }}
                            >
                                <FormattedMessage
                                    id='0uS5Uj'
                                    defaultMessage='Copy synchronization ID'
                                />
                            </Button>
                        </>
                    }
                    title={synchronizationId}
                ></Block>
            </GlobalReportDiv>
        </>
    );
};
