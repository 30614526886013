import { Immutable, produce } from 'immer';
import { BCCredentials } from '../model/BCCredentials';
import { ConnectionStatus } from '../model/ConnectionStatus';
import { ConnectorConfig } from '../model/ConnectorConfig';
import { ConnectorConfigOptions } from '../model/ConnectorConfigOptions';
import { BCCredentialsForm } from '../model/BCCredentialsForm';
import { ConnectorConfigForm } from '../model/ConnectorConfigForm';
import { BCCredentialsErrors } from '../model/BCCredentialsErrors';
import { ConnectorConfigErrors } from '../model/ConnectorConfigErrors';
import { isEqual } from 'lodash';
import { BcConfig } from '../model/BcConfig';
import { ConnectorBcConfigForm } from '../model/ConnectorBcConfigForm';
import { ConnectorBcConfigErrors } from '../model/ConnectorBcConfigErrors';
import { PreExistingCatalogIdentifierValues } from '../model/PreExistingCatalogIdentifierValues';

export type State = Immutable<{
    bcCredentialsForm: BCCredentialsForm;
    connectorConfigForm: ConnectorConfigForm;
    connectorBcConfigForm: ConnectorBcConfigForm;
    bcConnectionStatus: ConnectionStatus;
    connectorConfigOptions: ConnectorConfigOptions | null;
    preExistingCatalogIdentifierValues: PreExistingCatalogIdentifierValues | null;
}>;

export const initialState: State = {
    bcCredentialsForm: {
        defaultData: null,
        data: null,
        errors: {},
        isDirty: false,
    },
    connectorConfigForm: {
        defaultData: null,
        data: null,
        errors: {},
        isDirty: false,
    },
    connectorBcConfigForm: {
        defaultData: null,
        data: null,
        isDirty: false,
    },
    bcConnectionStatus: ConnectionStatus.None,
    connectorConfigOptions: null,
    preExistingCatalogIdentifierValues: null,
};

export type Action =
    | {
          type: 'fetchConfiguration/fulfilled';
          payload: {
              bcCredentials: BCCredentials | null;
              connectorConfig: ConnectorConfig | null;
              connectorBcConfig: BcConfig | null;
              bcConnectionStatus: ConnectionStatus;
          };
      }
    | {
          type: 'getConnectorConfigOptions/fulfilled';
          connectorConfigOptions: ConnectorConfigOptions;
      }
    | {
          type: 'getPreExistingCatalogIdentifierValues/fulfilled';
          preExistingCatalogIdentifierValues: PreExistingCatalogIdentifierValues;
      }
    | {
          type: 'bcCredentialsForm/changed';
          bcCredentials: BCCredentials;
      }
    | {
          type: 'connectorConfigForm/changed';
          connectorConfig: ConnectorConfig;
      }
    | {
          type: 'bcConfigForm/changed';
          bcConfig: BcConfig;
      }
    | {
          type: 'registerBCCredentials/fulfilled';
          payload: {
              connectionStatus: ConnectionStatus;
          };
      }
    | {
          type: 'saveConnectorConfig/fulfilled';
      }
    | {
          type: 'saveBcConfig/fulfilled';
      }
    | {
          type: 'registerBCCredentials/rejected';
          errors: BCCredentialsErrors;
      }
    | {
          type: 'saveConnectorConfig/rejected';
          errors: ConnectorConfigErrors;
      }
    | {
          type: 'saveBcConfig/rejected';
          errors: ConnectorBcConfigErrors;
      };

export const reducer = produce<(draft: State, action: Action) => State>(
    (draft, action) => {
        switch (action.type) {
            case 'fetchConfiguration/fulfilled':
                if (Object.keys(draft.bcCredentialsForm.errors).length === 0) {
                    draft.bcCredentialsForm = {
                        defaultData: action.payload.bcCredentials,
                        data: action.payload.bcCredentials,
                        errors: {},
                        isDirty: false,
                    };
                }
                if (
                    Object.keys(draft.connectorConfigForm.errors).length === 0
                ) {
                    draft.connectorConfigForm = {
                        defaultData: action.payload.connectorConfig,
                        data: action.payload.connectorConfig,
                        errors: {},
                        isDirty: false,
                    };
                }
                draft.connectorBcConfigForm = {
                    defaultData: action.payload.connectorBcConfig,
                    data: action.payload.connectorBcConfig,
                    isDirty: false,
                };

                draft.bcConnectionStatus = action.payload.bcConnectionStatus;
                break;

            case 'getConnectorConfigOptions/fulfilled':
                draft.connectorConfigOptions = action.connectorConfigOptions;
                break;

            case 'getPreExistingCatalogIdentifierValues/fulfilled':
                draft.preExistingCatalogIdentifierValues =
                    action.preExistingCatalogIdentifierValues;
                break;

            case 'bcCredentialsForm/changed':
                draft.bcCredentialsForm.data = action.bcCredentials;
                draft.bcCredentialsForm.isDirty = !isEqual(
                    action.bcCredentials,
                    draft.bcCredentialsForm.defaultData,
                );
                break;

            case 'connectorConfigForm/changed':
                draft.connectorConfigForm.data = action.connectorConfig;
                draft.connectorConfigForm.isDirty = !isEqual(
                    action.connectorConfig,
                    draft.connectorConfigForm.defaultData,
                );
                break;
            case 'bcConfigForm/changed':
                draft.connectorBcConfigForm.data = action.bcConfig;
                draft.connectorBcConfigForm.isDirty = !isEqual(
                    action.bcConfig,
                    draft.connectorBcConfigForm.defaultData,
                );
                break;
            case 'registerBCCredentials/fulfilled':
                draft.bcCredentialsForm = {
                    defaultData: draft.bcCredentialsForm.data,
                    data: draft.bcCredentialsForm.data,
                    errors: {},
                    isDirty: false,
                };
                draft.bcConnectionStatus = action.payload.connectionStatus;
                break;

            case 'saveBcConfig/fulfilled':
                draft.connectorBcConfigForm = {
                    defaultData: draft.connectorBcConfigForm.data,
                    data: draft.connectorBcConfigForm.data,
                    isDirty: false,
                };
                break;
            case 'saveConnectorConfig/fulfilled':
                draft.connectorConfigForm = {
                    defaultData: draft.connectorConfigForm.data,
                    data: draft.connectorConfigForm.data,
                    errors: {},
                    isDirty: false,
                };
                break;
            case 'registerBCCredentials/rejected':
                draft.bcCredentialsForm.errors = action.errors;
                break;

            case 'saveConnectorConfig/rejected':
                draft.connectorConfigForm.errors = action.errors;
                break;
        }

        return draft;
    },
);
