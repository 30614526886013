import { apiFetch } from '../../../../../util/apiFetch';
import { AssetAttributesPerAssetFamily } from '../model/AssetAttributesPerAssetFamily';
import { AssetCollectionAttributes } from '../model/AssetCollectionAttributes';

type Result = {
    data: {
        assetCollectionAttributes: AssetCollectionAttributes[];
        assetAttributesPerAssetFamily: {
            [asset_family_code: string]: AssetAttributesPerAssetFamily[];
        };
    };
};

export const getPimAssetAttributes = (storeId: string) =>
    apiFetch<Result>(`${storeId}/mapping/pim-asset-attributes`).then(
        (result) => result.data,
    );
