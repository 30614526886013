import { apiFetch } from '../../../../util/apiFetch';
import { MediaSource } from '../../model/MediaSource';
import { MediaMapping } from '../model/MediaMapping';

type Result =
    | {
          source: 'image';
          attributeCode: string | null;
      }
    | {
          source: 'asset_collection';
          attributeCode: string;
          assetAttributeCode: string;
      };

export const fetchMediaMapping = async (
    storeId: string,
): Promise<MediaMapping> => {
    const mediaMapping = await apiFetch<Result>(
        `${storeId}/mapping/retrieveProductVariantMediaMapping`,
    );

    if (mediaMapping.source === 'asset_collection') {
        return {
            source: MediaSource.Assets,
            attributeCode: mediaMapping.attributeCode,
            assetAttributeCode: mediaMapping.assetAttributeCode,
        };
    }

    return {
        source: MediaSource.Images,
        attributeCode: mediaMapping.attributeCode,
    };
};
