import { useIntl } from 'react-intl';
import { useToaster } from '../../../util/toast';
import { useState } from 'react';
import { resetAppConfiguration } from '../api/resetAppConfiguration';
import { useCurrentStore } from '../../MultiStore';

export const useResetConfiguration = () => {
    const intl = useIntl();
    const addToast = useToaster();
    const { currentStoreId } = useCurrentStore();

    const [isResetting, setIsResetting] = useState(false);

    const reset = async () => {
        setIsResetting(true);
        await resetAppConfiguration(currentStoreId);

        addToast(
            intl.formatMessage({
                id: 'uLA7xf',
                defaultMessage:
                    'Apps successfully reset, please refresh the page.',
            }),
            'success',
        );
        setIsResetting(false);
    };

    return { reset, isResetting };
};
