import { Button, Modal, ProductsIllustration } from 'akeneo-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useState } from 'react';
import { SynchronizationScope } from '../../model/SynchronizationScope';
import { SynchronizationScopeLabel } from '../../component/SynchronizationScopeLabel';
import { FiltersForm } from './FiltersForm';
import { FilterPimAttribute } from '../../model/FilterPimAttribute';

type Props = {
    closeModal: () => void;
    onLaunch: (
        minCompleteness: number | null,
        priceImport: boolean,
        withCustomFields: boolean,
        withImages: boolean,
        withImportPreExistingProductsOnly: boolean,
        withCategories: boolean,
        filterAttribute: string | null,
    ) => void;
    scope: SynchronizationScope;
    initialFilterAttribute: string | null;
    filterPimAttributes: FilterPimAttribute[];
    isPreExistingCatalogMappingConfigured: boolean;
    isProductMediaMappingEmpty: boolean;
    isCustomFieldMappingEmpty: boolean;
};

const SynchronizationModal = ({
    closeModal,
    onLaunch,
    scope,
    filterPimAttributes,
    isPreExistingCatalogMappingConfigured,
    isProductMediaMappingEmpty,
    isCustomFieldMappingEmpty,
}: Props) => {
    const [minCompleteness, setMinCompleteness] = useState<number | null>(null);
    const [priceImport, setPriceImport] = useState<boolean>(true);
    const [withCustomFields, setCustomFieldsFilter] = useState<boolean>(true);
    const [withImages, setImagesFilter] = useState<boolean>(true);
    const [
        withImportPreExistingProductsOnly,
        setImportPreExistingProductsOnlyFilter,
    ] = useState<boolean>(true);
    const [filterAttribute, setFilterAttribute] = useState<string | null>(null);
    const [withCategories, setCategoriesFilter] = useState<boolean>(true);

    const handleLaunchSynchronization = () => {
        onLaunch(
            minCompleteness,
            priceImport,
            withCustomFields,
            withImages,
            withImportPreExistingProductsOnly,
            withCategories,
            filterAttribute,
        );
        closeModal();
    };

    const intl = useIntl();
    return (
        <Modal
            onClose={closeModal}
            closeTitle={intl.formatMessage({
                id: '47FYwb',
                defaultMessage: 'Cancel',
            })}
            illustration={<ProductsIllustration />}
        >
            <Modal.SectionTitle color='brand'>
                <FormattedMessage
                    id='zJoa5X'
                    defaultMessage='Manual synchronization'
                />
            </Modal.SectionTitle>
            <Modal.Title>
                <SynchronizationScopeLabel scope={scope} />
            </Modal.Title>

            <FiltersForm
                scope={scope}
                minCompleteness={minCompleteness}
                setMinCompleteness={setMinCompleteness}
                priceImport={priceImport}
                setPriceImport={setPriceImport}
                withCustomFields={withCustomFields}
                setCustomFieldsFilter={setCustomFieldsFilter}
                withImages={withImages}
                setImagesFilter={setImagesFilter}
                withImportPreExistingProductsOnly={
                    withImportPreExistingProductsOnly
                }
                setImportPreExistingProductsOnlyFilter={
                    setImportPreExistingProductsOnlyFilter
                }
                filterAttribute={filterAttribute}
                setFilterAttribute={setFilterAttribute}
                withCategories={withCategories}
                setCategoriesFilter={setCategoriesFilter}
                filterPimAttributes={filterPimAttributes}
                isPreExistingCatalogMappingConfigured={
                    isPreExistingCatalogMappingConfigured
                }
                isProductMediaMappingEmpty={isProductMediaMappingEmpty}
                isCustomFieldMappingEmpty={isCustomFieldMappingEmpty}
            />

            <Modal.BottomButtons>
                <Button level='tertiary' onClick={closeModal}>
                    <FormattedMessage id='47FYwb' defaultMessage='Cancel' />
                </Button>
                <Button level='primary' onClick={handleLaunchSynchronization}>
                    <FormattedMessage
                        id='yAOnoV'
                        defaultMessage='Launch synchronization'
                    />
                </Button>
            </Modal.BottomButtons>
        </Modal>
    );
};

export { SynchronizationModal };
