import React, { useEffect, useState } from 'react';
import { SynchronizationScope } from '../../model/SynchronizationScope';
import { Synchronization } from '../../model/Synchronization';
import { useToaster } from '../../../../util/toast';
import { useIntl } from 'react-intl';
import { useSelector } from '../../store/StoreProvider';
import { SynchronizationStatus } from '../../model/SynchronizationStatus';
import { ActivityIcon } from 'akeneo-design-system';

type SynchronizationMap = { [key in SynchronizationScope]: Synchronization };

const useNotifyFinishedSynchronization = () => {
    const addToast = useToaster();
    const intl = useIntl();

    const [previousSynchronizations, setPreviousSynchronizations] =
        useState<SynchronizationMap | null>(null);

    const synchronizations = useSelector((state) => state.synchronizations);

    useEffect(() => {
        if (previousSynchronizations === null) {
            setPreviousSynchronizations(synchronizations);
            return;
        }
        if (
            isSynchronizationFinished(
                previousSynchronizations,
                synchronizations,
            )
        ) {
            addToast(
                intl.formatMessage({
                    id: 'GLyjFZ',
                    defaultMessage: 'Synchronization finished',
                }),
                'info',
                <ActivityIcon />,
                intl.formatMessage({
                    defaultMessage:
                        'See the History section below to see the status of the synchronization.',
                    id: 'COifSF',
                }),
            );
        }
        setPreviousSynchronizations(synchronizations);
    }, [previousSynchronizations, synchronizations, addToast, intl]);
};

const isSynchronizationFinished = (
    previousSynchronizations: SynchronizationMap,
    synchronizations: SynchronizationMap,
) => {
    const startedSynchronizationStatus = [
        SynchronizationStatus.Pending,
        SynchronizationStatus.InProgress,
    ];

    return (
        Object.values(previousSynchronizations).filter(
            (previousSynchronization) =>
                startedSynchronizationStatus.includes(
                    previousSynchronization.status,
                ),
        ).length > 0 &&
        Object.values(synchronizations).filter((synchronization) =>
            startedSynchronizationStatus.includes(synchronization.status),
        ).length === 0
    );
};

export { useNotifyFinishedSynchronization };
