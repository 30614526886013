import { apiFetch, BadRequestError } from '../../../util/apiFetch';

type Credentials = {
    storeHash: string;
    accessToken: string;
};

export const registerBCCredentials = (
    credentials: Credentials,
    storeId: string,
) =>
    apiFetch(`${storeId}/configuration/registerBcCredentials`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            access_token: credentials.accessToken,
        }),
    });

export const isRegisterBCCredentialsValidationError = (
    error: unknown,
): error is BadRequestError<{
    errors: {
        storeHash: string[];
        accessToken: string[];
    };
}> => error instanceof BadRequestError;
