import { MultiSelectInput } from 'akeneo-design-system';
import { BCAttribute } from '../../model/BCAttribute';
import { selectAllowedPimAttributes } from '../selector/selectAllowedPimAttributes';
import { useSelector } from '../store/StoreProvider';
import { useIntl } from 'react-intl';

type Props = {
    bcAttribute: BCAttribute;
    selectedPimAttributeCodes: string[];
    disabled?: boolean;
    onChange: (pimAttributeCodes: string[]) => void;
};

export const AllowedPimAttributeMultiselect = ({
    bcAttribute,
    selectedPimAttributeCodes,
    disabled,
    onChange,
}: Props) => {
    const intl = useIntl();

    const allowedPimAttributes = useSelector(
        selectAllowedPimAttributes(bcAttribute),
    );
    return (
        <MultiSelectInput
            emptyResultLabel={intl.formatMessage({
                id: 'GAA3pc',
                defaultMessage: 'No result found',
            })}
            onChange={onChange}
            placeholder={intl.formatMessage({
                id: 'yyTpXX',
                defaultMessage: 'Select an Akeneo PIM attributes',
            })}
            openLabel=''
            removeLabel={intl.formatMessage({
                id: 'G/yZLu',
                defaultMessage: 'Remove',
            })}
            readOnly={disabled}
            value={selectedPimAttributeCodes}
        >
            {allowedPimAttributes.map((pimAttribute) => (
                <MultiSelectInput.Option
                    key={pimAttribute.code}
                    value={pimAttribute.code}
                >
                    {pimAttribute.label}
                </MultiSelectInput.Option>
            ))}
        </MultiSelectInput>
    );
};
