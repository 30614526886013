import React, { useState } from 'react';
import styled from 'styled-components';

import { useQuery } from 'react-query';
import { useCurrentStore } from '../MultiStore';
import { getSynchronizationReport } from './api/getSynchronizationReport';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loading } from '../../component';
import { Button } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import { SynchronizationReportModel } from './model/SynchronizationReportModel';
import { StatusReport } from './component/StatusReport';
import { ConfigurationGlogalReport } from './component/ConfigurationGlogalReport';
import { DetailedReport } from './component/DetailedReport';
import { LogsReport } from './component/LogsReport';

const Container = styled.div`
    margin-top: 32px;
`;

export const SynchronizationReport = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { currentStoreId } = useCurrentStore();
    const [synchronizationReport, setCurrentSynchronizationReport] =
        useState<SynchronizationReportModel | null>(null);

    const { isFetching } = useQuery(
        'getSynchronizationReport',
        () =>
            Promise.all([
                getSynchronizationReport(
                    currentStoreId,
                    location.state.synchronizationId,
                ),
            ]),
        {
            onSuccess: ([synchronizationReport]) => {
                setCurrentSynchronizationReport(synchronizationReport);
            },
        },
    );

    if (isFetching) {
        return <Loading />;
    }

    let haveLogs: boolean = false;
    if (
        synchronizationReport!.logs.error.length > 0 ||
        synchronizationReport!.logs.warning.length > 0 ||
        synchronizationReport!.logs.info.length > 0
    ) {
        haveLogs = true;
    }

    return (
        <>
            <Container>
                <Button ghost level='secondary' onClick={() => navigate('..')}>
                    <FormattedMessage
                        id='2hTPIN'
                        defaultMessage='BACK TO HISTORY'
                    />
                </Button>

                <br />
                <br />
                <StatusReport
                    synchronizationModel={
                        synchronizationReport!.synchronization
                    }
                    globalReport={synchronizationReport!.global_report}
                />
                <ConfigurationGlogalReport
                    configuration={synchronizationReport!.configuration}
                    synchronization={synchronizationReport!.synchronization}
                    globalReport={synchronizationReport!.global_report}
                    synchronizationId={location.state.synchronizationId}
                />
                <br />
                <DetailedReport
                    detailedReport={synchronizationReport!.detailed_report}
                />
                {haveLogs && (
                    <>
                        <br />
                        <LogsReport
                            logsReport={synchronizationReport!.logs}
                            synchronizationId={location.state.synchronizationId}
                        />
                    </>
                )}
            </Container>
        </>
    );
};
