import { apiFetch } from '../../../util/apiFetch';
import { SynchronizationHistory } from '../model/SynchronizationHistory';
import { SynchronizationHistoryStatus } from '../model/SynchronizationHistoryStatus';
import { SynchronizationScope } from '../model/SynchronizationScope';
import { SynchronizationType } from '../model/SynchronizationType';

type Result = Array<{
    id: string;
    created_at: string;
    ended_at: string;
    type: 'FULL' | 'DIFFERENTIAL';
    reference_date: string;
    scope: 'CATEGORY' | 'PRODUCT' | 'PRODUCT_VARIANT';
    status: 'SUCCESS' | 'WARNING' | 'ERROR';
    warning_count: number;
    error_count: number;
    is_scheduled_sync: boolean;
    report_version: number;
}>;

export const fetchSynchronizationHistories = (
    storeId: string,
): Promise<SynchronizationHistory[]> =>
    apiFetch<Result>(
        `${storeId}/synchronization/retrieveExecutedSynchronizations`,
    ).then((result) =>
        result.map((data) => {
            const synchronizationHistory: Partial<SynchronizationHistory> = {
                id: data.id,
                createdAt: data.created_at,
                endedAt: data.ended_at,
                scope:
                    data.scope === 'CATEGORY'
                        ? SynchronizationScope.Category
                        : data.scope === 'PRODUCT'
                          ? SynchronizationScope.Product
                          : SynchronizationScope.ProductVariant,
                type:
                    data.type === 'FULL'
                        ? SynchronizationType.Full
                        : SynchronizationType.Differential,
                status:
                    data.status === 'SUCCESS'
                        ? SynchronizationHistoryStatus.Success
                        : data.status === 'WARNING'
                          ? SynchronizationHistoryStatus.Warning
                          : data.status === 'ERROR'
                            ? SynchronizationHistoryStatus.Error
                            : SynchronizationHistoryStatus.Stopped,
                isScheduledSync: data.is_scheduled_sync,
                reportVersion: data.report_version,
            };
            if (
                synchronizationHistory.type === SynchronizationType.Differential
            ) {
                synchronizationHistory.referenceDate = data.reference_date;
            }
            if (
                synchronizationHistory.status ===
                SynchronizationHistoryStatus.Warning
            ) {
                synchronizationHistory.warningCount = data.warning_count;
                synchronizationHistory.errorCount = data.error_count;
            }

            return synchronizationHistory as SynchronizationHistory;
        }),
    );
