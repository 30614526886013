import { Badge } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BCAttribute } from '../../../model/BCAttribute';
import { MappingStatus } from '../../../model/MappingStatus';
import { selectAttributeMapping } from '../../selector/selectAttributeMapping';
import { useSelector } from '../../store/StoreProvider';

type Props = {
    bcAttribute: BCAttribute;
};

export const MappingState = ({ bcAttribute }: Props) => {
    const attributeMapping = useSelector(
        selectAttributeMapping(bcAttribute.code),
    );

    const pimFamilyCount = useSelector(
        (state) => Object.keys(state.pimFamilies).length,
    );

    return (
        <>
            {MappingStatus.Complete === attributeMapping.status && (
                <Badge level='primary'>
                    <FormattedMessage id='U78NhE' defaultMessage='Complete' />
                </Badge>
            )}
            {MappingStatus.InProgress === attributeMapping.status &&
                attributeMapping.hasAttributePerFamily && (
                    <Badge level='secondary'>
                        <FormattedMessage
                            id='gTARrW'
                            defaultMessage='In progress {cur}/{max}'
                            values={{
                                cur: Object.values(
                                    attributeMapping.hasCollectionOfAttribute
                                        ? attributeMapping.pimAttributeCodesPerFamily
                                        : attributeMapping.pimAttributeCodePerFamily,
                                ).filter(
                                    (pimAttributeCodes) =>
                                        null !== pimAttributeCodes,
                                ).length,
                                max: pimFamilyCount,
                            }}
                        />
                    </Badge>
                )}
        </>
    );
};
