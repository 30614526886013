import { Immutable } from 'immer';
import { useCurrentStore } from '../../../MultiStore';
import { Data, saveMapping } from '../../api/saveMapping';
import { AttributeMapping } from '../../model/AttributeMapping';

export const useSaveMapping = () => {
    const { currentStoreId } = useCurrentStore();

    return (mapping: Immutable<Map<string, AttributeMapping>>) => {
        const data = Array.from(mapping.values()).reduce<Data>(
            (data, attributeMapping) => {
                if (attributeMapping.bcAttributeCode === 'IMAGES') {
                    return data;
                }
                let dataValue;
                if (attributeMapping.hasCollectionOfAttribute) {
                    dataValue = attributeMapping.hasAttributePerFamily
                        ? attributeMapping.pimAttributeCodesPerFamily
                        : attributeMapping.pimAttributeCodes;
                } else {
                    dataValue = attributeMapping.hasAttributePerFamily
                        ? attributeMapping.pimAttributeCodePerFamily
                        : attributeMapping.pimAttributeCode;
                }

                if (null !== dataValue) {
                    data[attributeMapping.bcAttributeCode] = {
                        hasAttributePerFamily:
                            attributeMapping.hasAttributePerFamily,
                        hasCollectionOfAttribute:
                            attributeMapping.hasCollectionOfAttribute,
                        data: dataValue,
                    };
                }
                return data;
            },
            {},
        );

        return saveMapping(data, currentStoreId);
    };
};
