import { apiFetch } from '../../../util/apiFetch';

type Result = {
    is_empty: boolean;
};

export const isProductMediaMappingEmpty = (currentStoreId: string) =>
    apiFetch<Result>(
        `${currentStoreId}/synchronization/is-product-media-mapping-empty`,
    ).then((result) => result);
