import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Badge } from 'akeneo-design-system';
import { useSelector } from '../store/StoreProvider';
import { MediaSource } from '../../../model/MediaSource';

export const MediaMappingState = () => {
    const { mediaSource, mediaMapping, assetMapping } = useSelector(
        (state) => state,
    );

    if (mediaSource === MediaSource.Images && mediaMapping.length > 0) {
        return (
            <Badge level='primary'>
                <FormattedMessage
                    id='qehEhL'
                    defaultMessage='{attributeCount, plural, one {# image attribute mapped} other {# image attributes mapped}}'
                    values={{
                        attributeCount: mediaMapping.length,
                    }}
                />
            </Badge>
        );
    }

    if (mediaSource === MediaSource.Assets && assetMapping.length > 0) {
        return (
            <Badge level='primary'>
                <FormattedMessage
                    id='n+UFNp'
                    defaultMessage='{attributeCount, plural, one {# asset attribute mapped} other {# asset attributes mapped}}'
                    values={{
                        attributeCount: assetMapping.length,
                    }}
                />
            </Badge>
        );
    }

    return (
        <Badge level='tertiary'>
            <FormattedMessage id='y+FM2U' defaultMessage='Not complete' />
        </Badge>
    );
};
